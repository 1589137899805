<template>
  <div>
    <table class="table mt-1">
      <tbody>
        <tr>
          <td>Order</td>
          <td>วันที่รับ</td>
          <td>วันที่คืน</td>
          <td>Member</td>
          <td>Note</td>
          <td>ยอดเงิน</td>
        </tr>
        <tr v-for="(y, index) in table_data" :key="index">
          <td>
            <div>
              <b-button
                variant="relief-success"
                class="btn-icon"
                @click="open_new(y._id)"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </div>
          </td>
          <td>
            {{ y.start_date | day }}
            <b-badge variant="light-info"> {{ y.start_date | time }}</b-badge>
          </td>
          <td>
            {{ y.end_date | day }}
            <b-badge variant="light-info"> {{ y.end_date | time }}</b-badge>
          </td>
          <td>
            <div v-if="y.member">
              {{ y.member.first_name }}
              {{ y.member.last_name }}
              <span v-if="y.member.retro_username">
                ( {{ y.member.retro_username }} )
              </span>
              <span v-else-if="y.member.gen_username">
                ( {{ y.member.gen_username }} )
              </span>
            </div>
          </td>
          <td>
            <div v-if="y.note">{{ y.note }} (n)</div>
            <div v-if="y.description">{{ y.description }} (d)</div>
          </td>
          <td>{{ y.totalAmount | number }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">รวม</td>
          <td>
            <h4>{{ sumTotal | number }}</h4>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";

import { BButton, BBadge } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BBadge,
  },
  props: ["form", "payment_type"],
  watch: {
    form: {
      handler: "load_data",
      deep: true, // Use this if you need to watch nested data changes within the object
      immediate: true, // Use this if you want to run the handler immediately on mount
    },
    payment_type: {
      handler: "load_data",
      immediate: true, // This will also call load_data immediately when the component is mounted
    },
  },
  data() {
    return {
      table_data: [],
    };
  },
  created() {
    this.load_data();
  },

  computed: {
    sumTotal() {
      return this.table_data.reduce(
        (total, payment) => total + payment.totalAmount,
        0
      );
    },
  },

  methods: {
    load_data() {
      this.form.payment_type = this.payment_type;
      this.$http({
        method: "POST",
        url: `/account/bank-account/type/damage`,
        data: this.form,
      }).then((x) => {
        this.table_data = x.data.data;
      });
    },
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
