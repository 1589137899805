<template>
    <div>
        <b-card>
            <div class="d-flex justify-content-between">
                <div>
                    <h4>{{bank_account_info.account_name}} {{bank_account_info.bank_name}} {{bank_account_info.account_number|account_no}}</h4>
                    <hr>
                    <div class="d-flex justify-content-between mb-1">
                        <b-button @click="back()">Back</b-button> 
                        <b-form-select
                            class="ml-3"
                            v-model="mode_selected"
                            :options="options"
                        />
                    </div>
                </div>
                <date-picker @date_picked="date_picked" />
            </div>
            
            
            
            <!-- {{bank_account_id}} -->

            <table class="table">
               
                <tbody>
                    <tr>
                        <td>
                            order_id
                        </td>
                        <td>
                            payment_type
                        </td>
                        <td>
                            เข้า
                        </td>
                        <td>
                            ออก
                        </td>
                    </tr>
                    <tr v-for="x in bank_account_data" :key="x._id">
                        <td>
                            <a @click="$router.push({ name: 'order-view', params: { id: x.order_id }})"><u>{{x.order_id_text}}</u></a> <br><br>
                            
                            <a @click="$router.push({ name: 'member-view', params: { id: x.member_info._id }})">{{x.member_info.member_id}}</a><br>
                            {{x.member_info.full_name}}
                        </td>
                        <td>
                            {{x.payment_type}}
                        </td>
                        <td>
                            <span v-if="x.side == 'deposit'">{{x.amount}}</span>
                            <span v-else></span>
                        </td>
                        <td>
                            <span v-if="x.side == 'withdraw'">{{x.amount}}</span>
                            <span v-else></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-card>
    </div>
</template>

<script>
import { BCard,BRow,BCol,BButton,BFormSelect } from "bootstrap-vue";
import DatePicker from "./Datepicker.vue"
import moment from 'moment'
import 'moment-timezone';

export default {
    components: {
       BCard,
       BRow,
       BCol,
       BButton,
       DatePicker,
       BFormSelect
       
    },
    props:['bank_account_id'],
    data(){
        return{
            bank_account_data:[],
            bank_account_info:{},
            mode_selected:'transaction',
            form:{
                date:moment().format('YYYY-MM-DD'),
            },
            options: [
                { value: 'transaction', text: 'รายการเคลื่อนไหว' },
                { value: 'deposit', text: 'เงินประกัน' },
            ],
        }
    },
    created(){
        this.loadData(this.bank_account_id)
    },
    methods: {
        back(){
            this.$emit('back')
        },
        date_picked(x){
            this.form.date = x
            this.loadData()
        },
        loadData(bank_account_id){
            const params = {
                date:this.form.date,
                account_id:this.bank_account_id
            }
            this.$http({
                method: 'GET',
                url: `account/bank-account/`,
                params:params,
            }).then(x => {
                this.bank_account_data = x.data.data
                this.bank_account_info = x.data.info

               console.log(x.data)
            })
        }
    },
    filters:{
        account_no: function (phone) {
            return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, '$1-$2-$3-$4');
        },
        
    }
}
</script>