<template>
  <div>
    <b-modal
      id="modal-add-transaction"
      title="เงินเข้า / เงินออก adjust"
      ref="modal-add-transaction"
      hide-footer
    >
      <validation-observer ref="AddTransaction">
        <b-form-group label="เข้า / ออก ">
          <validation-provider
            #default="{ errors }"
            name="side"
            :rules="{ required: true }"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.side"
                name="some-radios"
                value="deposit"
                >เงินเข้า</b-form-radio
              >
              <b-form-radio
                v-model="form.side"
                name="some-radios"
                value="withdraw"
                >เงินออก</b-form-radio
              >
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="วันที่รับเงิน">
          <date-picker
            v-model="form.date"
            mode="datetime"
            is24hr
            :model-config="{
              type: 'string',
              mask: 'iso',
            }"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="formatDateTimeForDisplay(inputValue)"
                @click="togglePopover()"
                autocomplete="off"
              />
            </template>
          </date-picker>
        </b-form-group>
        <b-form-group label="ยอดสมุดบัญชี / ยอดภายใน">
          <validation-provider
            #default="{ errors }"
            name="side"
            :rules="{ required: true }"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.main"
                name="some-radios2"
                :value="true"
                >ยอดสมุดบัญชี</b-form-radio
              >
              <b-form-radio
                v-model="form.main"
                name="some-radios2"
                :value="false"
                >ยอดภายใน</b-form-radio
              >
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group v-if="!form.main" label="ประเภท ยอดภายใน">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="form.payment_type"
              name="some-radios3"
              value="rental"
              >ค่าเช่า</b-form-radio
            >
            <b-form-radio
              v-model="form.payment_type"
              name="some-radios3"
              value="shipping"
              >ค่าส่ง</b-form-radio
            >
            <b-form-radio
              v-model="form.payment_type"
              name="some-radios3"
              value="deposit"
              >เงินประกัน</b-form-radio
            >
            <b-form-radio
              v-model="form.payment_type"
              name="some-radios3"
              value="damage"
              >อุปกรณ์เสียหาย</b-form-radio
            >
            <b-form-radio
              v-model="form.payment_type"
              name="some-radios3"
              value="sale"
              >ขายอุปกรณ์</b-form-radio
            >
          </div>
        </b-form-group>
        <b-form-group label="ยอดเงิน">
          <validation-provider
            #default="{ errors }"
            name="amount"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="form.amount"
              placeholder="ยอดเงิน"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="note">
          <validation-provider
            #default="{ errors }"
            name="note"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="form.note"
              placeholder="รายละเอียด"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <hr />
      <div class="text-right justify">
        <b-button variant="relief-success" @click="validationForm" size="sm">
          แจ้งชำระเงิน
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required } from "@validations";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BButton,
    DatePicker,
  },
  props: ["account_id"],
  data() {
    return {
      form: {
        side: "deposit",
        main: true,
        payment_type: null,
        date: Date(),
      },
      loading: false,
    };
  },
  watch: {
    "form.main": function (newValue) {
      if (newValue) {
        this.form.payment_type = null;
      }
    },
  },
  methods: {
    formatDateTimeForDisplay(isoDateTime) {
      if (isoDateTime) {
        const parts = isoDateTime.split(" ");
        const dateParts = parts[0].split("/");
        const timePart = parts[1];
        const reformattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`;

        const date = new Date(reformattedDate);

        // Format the date and time in 'MM/DD/YYYY HH:mm' format
        const formattedDate = date.toLocaleString("th-TH", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        return formattedDate;
      }
    },
    validationForm() {
      this.loading = true;
      this.$refs.AddTransaction.validate().then((success) => {
        if (success) {
          this.form.account_id = this.account_id;
          // this.form.main = true;
          // this.form.date = new Date();
          this.$http({
            method: "POST",
            url: `/payment/admin-adjust`,
            data: this.form,
          })
            .then((x) => {
              this.$root.$emit("order-reload");
              this.$refs["modal-add-transaction"].hide();
              this.clearModal();
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error.response.data);
            });
        }
      });
    },
    clearModal() {
      this.form.amount = "";
    },
  },
};
</script>
