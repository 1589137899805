import { render, staticRenderFns } from "./BankAccountSingle.vue?vue&type=template&id=26c0fb08&"
import script from "./BankAccountSingle.vue?vue&type=script&lang=js&"
export * from "./BankAccountSingle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports