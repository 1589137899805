<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>ยังไม่ได้ตัดยอด</h4>
      <div>
        <!-- <h4>รวมเงินประกัน: {{ sum_deposit | number }} บาท</h4> -->
      </div>
    </div>
    <table class="table mt-1">
      <tbody>
        <tr>
          <td>Order</td>
          <td>วันที่รับ</td>
          <td>วันที่คืน</td>
          <td>Member</td>
          <td>DIFF</td>
        </tr>
        <tr v-for="(y, index) in data" :key="index">
          <td>
            <div v-if="y.order">
              <b-button
                variant="relief-success"
                class="btn-icon"
                @click="open_new(y.order_id)"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <br />
              <small>{{ y.order.order_id }}</small>
            </div>
          </td>
          <td>
            <div v-if="y.order">
              {{ y.order.start_date | day }} -
              {{ y.order.start_date | time }}
            </div>
          </td>
          <td>
            <div v-if="y.order">
              {{ y.order.end_date | day }} - {{ y.order.end_date | time }}
            </div>
          </td>
          <td>
            <div v-if="y.member">
              <div v-if="y.member.retro_username">
                ( {{ y.member.retro_username }} )
              </div>
              <div v-else-if="y.member.gen_username">
                {{ y.member.gen_username }}
              </div>
              {{ y.member.first_name }}
              {{ y.member.last_name }}
            </div>
            <span v-if="y.description">{{ y.description }} (d)</span>
            <span v-if="y.note">{{ y.note }} (n)</span>
          </td>
          <td>{{ y.diff | number }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  data() {
    return {
      sum_deposit: 0,
    };
  },
  methods: {
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    summ() {
      this.sum_deposit = this.data.reduce(
        (sum, item) => sum + item.difference,
        0
      );
    },
  },
  props: ["data"],
  watch: {
    // Watcher for the 'data' prop
    data(newVal, oldVal) {
      this.summ(); // Call the method you want to run
    },
  },
  filters: {
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        if (Number.isInteger(x)) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
